import React, { useContext, useEffect } from 'react'
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import SwiperSlide from './SwiperSlide';
import IntroAnim from './IntroAnim';
import { ProjectContext } from './ProjectContext';

export default function Landing() {

  let {projects} = useContext(ProjectContext);

  useEffect( () => {
    introAnim();
    blurNotHoverSlide();
    hoverCursor();

    document.title = 'Nelson Choi';

    const swiper = new Swiper('.swiper', {
      direction: 'horizontal',
      loop: false,
      freeMode: true,
      slidesPerView: 3.5,
      mousewheel: {
        eventsTarget: "body",
        sensitivity: 0.5
      },
      breakpoints: {
        320: {
          direction: 'vertical',
          slidesPerView: 'auto'
        },
        600: {
          direction: 'horizontal',
          slidesPerView: 2.5
        },
        1024: {
          slidesPerView: 2.5
        },
        1440: {
          slidesPerView: 3.5
        }
      }
    });
  }, []);

  function introAnim() {
    if (document.querySelector('.App.init') && document.querySelector(".intro-anim")) {
      let introAnim = document.querySelector(".intro-anim");
      let animItem = introAnim.querySelectorAll("span");
      let i = 0;
      function setAnim() {
        if (i < animItem.length-1) {
          animItem[i].style.display = "none";
          animItem[i+1].style.display = "block";
          i++;
        } else if (document.querySelector(".landing")) {
          introAnim.style.display = "none";
          document.querySelector('.App').classList.remove('init');
          document.querySelector(".landing").style.display = "block";
        }
      }
      setInterval(setAnim, 500);
    } else if (document.querySelector(".intro-anim")) {
      document.querySelector(".intro-anim").style.display = 'none';
    }
  }

  function blurNotHoverSlide() {
    let slides = document.querySelectorAll('.swiper-slide a');
    slides.forEach( slide => {
      slide.addEventListener('mouseover', () => {
        slides.forEach( e => {
          e.style.filter = 'blur(5px)';
        })
      });
      slide.addEventListener('mouseout', () => {
        slides.forEach( e => {
          e.style.filter = 'unset';
        })
      });
    });
  }

  function hoverCursor() {
    let slides = document.querySelectorAll('.swiper-slide a');
    let cursorX, cursorY;
    slides.forEach( slide => {
      let cursorText = slide.parentElement.querySelector('.cursor-text');
      let compStyles = window.getComputedStyle(slide);
      let marginOffSet = compStyles.getPropertyValue('margin');
      slide.addEventListener('mouseover', () => {
        cursorText.style.display = 'block';
      });
      slide.addEventListener('mouseover', () => {
        slide.style.cursor = 'none';
      });
      slide.addEventListener('mouseout', () => {
        cursorText.style.display = 'none';
      });
      slide.addEventListener('mousemove', (e) => {
        cursorPos(e);
        cursorText.style.top = 'calc(' + cursorY + 'px + ' + marginOffSet + ')';
        cursorText.style.left = 'calc(' + cursorX + 'px + ' + marginOffSet + ')';
      });
    });

    function cursorPos(e) {
      const target = e.target;
      const rect = target.parentElement.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      cursorX = x;
      cursorY = y;
    }
  }

  return (
    <>
    <IntroAnim />
    <div className="landing">
      <p className="introduction">Hi there! I’m Nelson Choi. I design human interface. Currently based in Toronto. Previously worked on big and small projects for NGOs, corporates and the government in Hong Kong:</p>
      <div className="swiper">
        <div className="swiper-wrapper">
          {
          projects && projects.map( (project) => {
            return (
              <SwiperSlide key={project.id} id={project.id} title={project.name} cate={project.cate} imgURL={project.thumbnail} slug={project.slug} />
            ) 
          })
          }
        </div>
      </div>

      <div className="footer">
        <ul>
          <li><a href="https://www.linkedin.com/in/nelsonchoi98" target="_blank" rel="noreferrer">LinkedIn</a></li>
          <li><a href="mailto:kwanhochoi1@gmail.com" target="_blank" rel="noreferrer">Email</a></li>
        </ul>
        <span className="made-by-nelson">
          <span className="made-by-nelson-content" style={{ backgroundImage: 'url("/images/made_by_nelson.svg")' }}></span>
        </span>
      </div>
    </div>
    </>
  )
}
