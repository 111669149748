import React from 'react'
import { Link } from 'react-router-dom';

export default function Error() {
  return (
    <>
      <h1>Ops...something went wrong.</h1>
      <Link to="/" ><span className="material-symbols-outlined">subdirectory_arrow_right</span>See Index</Link>
    </>
  )
}
