import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import { ProjectContext } from './components/ProjectContext';
import Landing from './components/Landing';
import Detail from './components/Detail';
import Error from './components/Error';

function App() {

  class sticker {
    constructor(x, y, color, rotate, state) {
      this.x = x;
      this.y = y;
      this.color = color;
      this.rotate = rotate;
      this.state = state;
    }
  }
  
  const [viewCount, setViewCount] = useState([]);
  const [projects, setProjects] = useState(() => {
    fetch('/projects.json').then( response => response.json() ).then( (data) => {
      setProjects(data);
      let length = data.length;
      let initViewCount = [];
      for (let i=0; i<length; i++) {
        initViewCount[i] = [];
      }
      setViewCount(initViewCount);
    })
  });

  return (
    projects &&
    <div className="App init">
      <BrowserRouter>
        <ProjectContext.Provider value={{projects, viewCount, setViewCount, sticker}}>
          <Routes>
            <Route path='/' element={<Landing />} ></Route>
            <Route path='/project' element={<Detail />} >
              <Route path=':slug' element={<Detail />}></Route>
            </Route>
            <Route path='*' element={<Error />} ></Route>
          </Routes>
        </ProjectContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
