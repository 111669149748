import React, { useState, useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { ProjectContext } from './ProjectContext';

export default function Detail() {

  let colors = ['#303030', '#2F4C42', 'orange', '#3745A8'];
  let {projects} = useContext(ProjectContext);
  let {slug} = useParams() // <= get the params from url
  let [thisProject, setThisProject] = useState(null);

  useEffect( () => {
    updateThisProject();
  }, [slug]);

  function updateThisProject() {
    window.scrollTo(0,0);
    setTimeout(()=> {
      let current = projects.find( (project) => {
        return project.slug === slug;
      })
      setThisProject(current);
      document.title = current.name+' - Nelson Choi';
    }, 300);
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  function projectNav() {

    let prevScrollpos = window.scrollY;
    let navHeight = document.querySelector(".nav-title").clientHeight;
    window.onscroll = function() {
      var currentScrollPos = window.scrollY;
      if (document.querySelector(".project-nav")) {
        if (prevScrollpos > currentScrollPos || currentScrollPos < 10) {
          document.querySelector(".project-nav").style.top = 0;
        } else {
          document.querySelector(".project-nav").style.top = -1 * navHeight + 'px';
        }
        prevScrollpos = currentScrollPos;
      }
    }
    
  }

  function markText() {
    let marks = document.querySelectorAll('mark');
    marks.forEach( mark => {
      mark.style.backgroundColor = colors[getRandomInt(0, colors.length-1)];
    });
  }

  return (
    <>
    { thisProject && 
      <div className="project-content" onLoad={()=>{projectNav(); markText();}}>
        <div className="project-nav">
          <Link className="btn-back" to="/"><span className="material-symbols-outlined">arrow_back_ios_new</span></Link>
          <p className="nav-title">{thisProject.name}</p>
        </div>
        <div className="project-detail">
          { thisProject.link &&
            <a className="project-link" href={thisProject.link} target='_blank' rel="noreferrer">{thisProject.link}</a>
          }
          {(() => {
            if (thisProject.video) {
              return ( <iframe className="project-img" src={thisProject.video} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Airliner Shorten"></iframe> )
            } else {
              return ( <img className="project-img" src={thisProject.thumbnail} alt="img" /> )
            }
          })()}
          <p><mark><span>{thisProject.headline}</span></mark></p>
          <p>{thisProject.paragraph}</p>
          {(() => {
            if (thisProject.img1) {
              return ( <img className="project-img" src={thisProject.img1} alt="img" /> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.caption1) {
              return ( <p>{thisProject.caption1}</p> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.img2) {
              return ( <img className="project-img" src={thisProject.img2} alt="img" /> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.caption2) {
              return ( <p>{thisProject.caption2}</p> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.img3) {
              return ( <img className="project-img" src={thisProject.img3} alt="img" /> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.caption3) {
              return ( <p>{thisProject.caption3}</p> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.img4) {
              return ( <img className="project-img" src={thisProject.img4} alt="img" /> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.caption4) {
              return ( <p>{thisProject.caption4}</p> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.img5) {
              return ( <img className="project-img" src={thisProject.img5} alt="img" /> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.caption5) {
              return ( <p>{thisProject.caption5}</p> )
            } else {
              return ( <></> )
            }
          })()}
          {(() => {
            if (thisProject.credit) {
              return ( <p>{thisProject.credit}</p> )
            } else {
              return ( <></> )
            }
          })()}
        </div>
      </div>
    }
    </>
  )
}
