import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import VanillaTilt from 'vanilla-tilt';
import { ProjectContext } from './ProjectContext';

export default function SwiperSlide({ id, title, cate, imgURL, slug }) {

  let colors = ['white', '#303030', 'blue', '#E4322B', 'yellow', '#2F4C42', 'orange', '#3745A8'];
  let {viewCount, setViewCount, sticker} = useContext(ProjectContext);

  useEffect( () => {
    VanillaTilt.init(document.querySelectorAll(".swiper-slide a"));

    for(let i=0; i<viewCount[id].length; i++) {
      let currentSticker = viewCount[id][i];
      addSticker(currentSticker.x, currentSticker.y, currentSticker.color, currentSticker.rotate, currentSticker.state);
    }
  }, []);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  function addSticker(x, y, color, rotate, state) {
    let projectIdx = document.querySelector('#project-' + id + ' a');
    let sticker = document.createElement('span');
    sticker.innerHTML = '<span class="front"><span class="content" style="transform: rotate(' + rotate + 'deg); background-image: url(/images/sticker_viewed.svg)"></span></span><span class="back"></span>';
    if (state === 0) {
      sticker.classList.add('sticker', 'new');
    } else if (state === 1) {
      sticker.classList.add('sticker', 'old');
    }
    
    sticker.style.backgroundColor = color;
    sticker.style.top = y + '%';  // define sticker y position
    sticker.style.left = x + '%'; // define sticker x position
    projectIdx.appendChild(sticker);
  }

  function addViewCount() {
    let newViewCount = [...viewCount];
    let newViewCountIdx = [...newViewCount[id]];

    newViewCount.map( (subArray) => {
      return subArray.map( (array) => {
        return array.state = 1;
      } )
    } )

    newViewCountIdx.push(
      new sticker(
        getRandomInt(0, 80), 
        getRandomInt(0, 80), 
        colors[getRandomInt(0, colors.length-1)],
        getRandomInt(0, 180),
        0)
    );
    newViewCount[id] = newViewCountIdx;
    setViewCount(newViewCount);
  }

  return (
    <div id={"project-" + id} className="swiper-slide">
      <span className="cursor-text"><span className="material-symbols-outlined">subdirectory_arrow_right</span>{title}</span>
      <Link className='swiper-slide-link' to={"/project/" + slug} data-tilt onClick={addViewCount}>
        <div className="meta">
          <span className="meta-title">{title}</span>
          <span className="meta-cate">{cate}</span>
        </div>
        <img className="thumbnail-img" src={imgURL} alt="img" />
      </Link>
    </div>
  )
}
