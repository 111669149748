import React from 'react'

export default function IntroAnim() {
  return (
    <div className="intro-anim">
      <span className="anim-item">Hi</span>
      <span className="anim-item">there</span>
      <span className="anim-item">I’m</span>
      <span className="anim-item">Nelson</span>
      <span className="anim-item">Choi</span>
    </div>
  )
}
